import Keys from '../keys/keys.js';
import store from '../state/store.js';
import queryString from 'query-string';
import {v4 as Uuid}  from 'uuid';
import $ from 'jquery';

import packageDetails from '../../package.json';
import {setUserProfile, setUserRoles} from 'actions/user.js';

const ErrorCodes = {
    InvalidSession:1003,
    NotAdmin:1009
}

class DatabaseService {
    constructor(){
        this.setLoggedIn(localStorage.isLoggedIn);
        this.setInstallationId(localStorage.installationId);
        delete localStorage.token; // Deprecated: Only here to make sure old clients clear this variable
        this.handleHttpErrors = this.handleHttpErrors.bind(this);

    }

    setTranslator(i18n) {
        this.i18n = i18n;
    }

    setLoggedIn(isLoggedIn) {
        isLoggedIn = isLoggedIn == 'true' || isLoggedIn === true;
        localStorage.isLoggedIn = isLoggedIn;
        store.dispatch({type:'SET_USER_LOGGED_IN', loggedIn:isLoggedIn});
    }

    setInstallationId(installationId) {
        if(!installationId || installationId.length < 10) {
            var uuid = Uuid();
            installationId = uuid + '-web';
        }
        localStorage.installationId = installationId;
    }

    destroySession() {
        delete localStorage.isLoggedIn;
        delete localStorage.ownerCompanyId;
        delete localStorage.companyId;
        delete localStorage.originalCompanyId;
        delete localStorage.master;
        delete localStorage.inviteCode;
        delete localStorage.companyCanOwn;
        delete localStorage.userRole;
        store.dispatch({type:'SET_USER_LOGGED_IN', loggedIn: false}); //Clear redux store
    }

    setRoles(roles) {
        store.dispatch({type:'SET_USER_ROLES', roles:roles});
        localStorage.userRole = roles.length ? roles[0].lc_name : null
    }

    setRoleAllowedRoutes(roleAllowedRoutes) {
        store.dispatch({type:'SET_ROLE_ALLOWED_ROUTES', routes:roleAllowedRoutes});
    }

    //Authentication
    signOut() {
        return this.httpJson('GET', '/logout', {}).then(() => {
            this.destroySession()
        });
    }

    signUp(username, password, code, profile) {
        return this.httpJson('POST', '/register', {username:username,password:password,code:code,profile:profile})
            .then(result => {
                // Temp fix: Real fix could be 
                // 1. we need to add a new column parent companyId (Preferred)
                // 2. Joining new company carries role from previous company, but switching back and forth could be a problem
                //localStorage.ownerCompanyId = result.company.ownerCompanyId == 128 ? result.company.id : result.company.ownerCompanyId || result.company.id;
                localStorage.ownerCompanyId = result.company.id;
                if(result.company.companyTier && result.company.companyTier.tier >= 4) {
                    localStorage.companyCanOwn = true;
                } else {
                    localStorage.companyId = result.company.id;
                    localStorage.inviteCode = result.company.code;
                }
                localStorage.originalCompanyId = result.company.id;
                localStorage.master = result.userProfile.master;
                this.setLoggedIn(true)
                store.dispatch(setUserProfile(result.userProfile));
                return result;
            });
    }

    signIn(username, password) {
        return this.httpJson('POST', '/login', {username:username,password:password})
            .then(result => {
                // Temp fix: Real fix could be 
                // 1. we need to add a new column parent companyId (Preferred)
                // 2. Joining new company carries role from previous company, but switching back and forth could be a problem
                //localStorage.ownerCompanyId = result.company.ownerCompanyId == 128 ? result.company.id : result.company.ownerCompanyId || result.company.id;
                localStorage.ownerCompanyId = result.company.id;
                if(result.company.companyTier && result.company.companyTier.tier >= 4) {
                    localStorage.companyCanOwn = true;
                } else {
                    localStorage.companyId = result.company.id;
                    localStorage.inviteCode = result.company.code;
                }
                localStorage.originalCompanyId = result.company.id;
                localStorage.master = result.userProfile.master;
                this.setLoggedIn(true)
                store.dispatch(setUserProfile(result.userProfile));
                return result;
            });

    }

    resetPassword(email) {
        return this.httpJson('PATCH', '/accounts', {email, password: {reset: true}})
            .then(result => {
                store.dispatch(setUserProfile(result.userProfile));
                return result;
            });
    }

    getRoles(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.ownerCompanyId}/employees/me/roles`, dataObj).then((result) => {
            this.setRoles(result.roles)
        });
    }

    getRoleAllowedRoutes(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.ownerCompanyId}/allowedRoutes`, dataObj).then((result) => {
            this.setRoleAllowedRoutes(result.data)
        });
    }

    // Assets
    getAssets(dataObj) {
        return this.httpJson('GET', '/assets', dataObj);
    }

    // Organizations
    getOrganizations(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.ownerCompanyId + '/organizations', dataObj);
    }

    addOrganization(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.ownerCompanyId + '/organizations', dataObj);
    }

    removeOrganization(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.ownerCompanyId + '/organizations/' + dataObj.childCompanyId , dataObj);
    }

    getOrganizationTiers(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.ownerCompanyId + '/tiers' , dataObj);
    }

    getPubSubUrl(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/pubsub/subscribe', dataObj);
    }

    getReportingjobs(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/reporting/jobs', dataObj);
    }


    //Dashboard
    getDashboard(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/dashboard', dataObj);
    }

    getReport(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/report', dataObj);
    }

    getEngagement(dataObj) {
        return this.http('GET', '/organizations/' + localStorage.companyId + '/engagement', dataObj);
    }


    // Goals
    getObjectives(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/objectives', dataObj);
    }

    getWellnessObjectives(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/objectives/wellness', dataObj);
    }

    addObjective(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/objectives', dataObj);
    }

    getObjective(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/objectives/' + dataObj.objectiveId, dataObj);
    }

    updateObjective(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/objectives/' + dataObj.objectiveId, dataObj);
    }

    removeObjective(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/objectives/' + dataObj.objectiveId, dataObj);
    }

    subscribeObjective(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/objectives/' + dataObj.objectiveId + '/subscribe', dataObj);
    }

    unsubscribeObjective(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/objectives/' + dataObj.objectiveId + '/unsubscribe', dataObj);
    }

    getCategories(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/goals', dataObj);
    }

    createCategory(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/goals', dataObj);
    }

    getCategoryById(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.id, dataObj);
    }

    updateCategory(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.categoryId, dataObj);
    }

    deleteCategoryById(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.id, dataObj);
    }

    goals_categoryId_badge_post(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.categoryId + '/badge', dataObj);
    }

    goals_categoryId_badge_del(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.categoryId + '/badge', dataObj);
    }

    goals_categoryId_leaderboardBanner_post(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.categoryId + '/leaderboardBanner', dataObj);
    }

    goals_categoryId_leaderboardBanner_del(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.categoryId + '/leaderboardBanner', dataObj);
    }

    activities_challengeId_image_post(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/activities/' + dataObj.challengeId + '/image', dataObj);
    }

    activities_challengeId_image_del(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/activities/' + dataObj.challengeId + '/image', dataObj);
    }

    goals_categoryId_subscribe(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.categoryId + '/subscribe', dataObj);
    }

    goals_categoryId_unsubscribe(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/goals/' + dataObj.categoryId + '/unsubscribe', dataObj);
    }

    remoteImage(dataObj) {
        return this.httpJson('GET', '/remote/image', dataObj);
    }


    // Employees
    getEmployees(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/employees', dataObj);
    }

    getEmployeesExport(dataObj) {
        return this.http('GET', '/organizations/' + localStorage.companyId + '/employees/export', dataObj);
    }

    getLeadsExport(dataObj) {
        return this.http('GET', '/organizations/' + localStorage.companyId + '/employees/leads', dataObj);
    }

    postEmployee(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/employees', dataObj);
    }

    postEmployees(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/employees', dataObj);
    }

    putEmployees(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/employees', dataObj);
    }

    putEmployeeById(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/employees/' + dataObj.employeeId, dataObj);
    }

    delEmployeeById(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/employees/' + dataObj.employeeId, dataObj);
    }

    putEmployeeByIdInvite(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/employees/' + dataObj.employeeId + '/invite', dataObj);
    }

    postEmployeeByIdTeamById(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/employees/' + dataObj.employeeId + '/team/' + dataObj.teamId, dataObj);
    }

    addCompanyAdmin(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/employees/' + dataObj.companyEmployeeId + '/admin', dataObj);
    }

    removeCompanyAdmin(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/employees/' + dataObj.companyEmployeeId + '/admin', dataObj);
    }

    getCompanyRoles(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/roles`, dataObj);
    }

    setEmployeeRole(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/employees/${dataObj.companyEmployeeId}/roles`, dataObj);
    }

    // Teams
    getTeams(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/teams', dataObj);
    }

    addTeam(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/teams', dataObj);
    }

    getTeam(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/teams/' + dataObj.teamId, dataObj);
    }

    updateTeam(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/teams/' + dataObj.teamId, dataObj);
    }

    removeTeam(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/teams/' + dataObj.teamId, dataObj);
    }

    getTeamEmployees(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/teams/' + dataObj.teamId + '/employees', dataObj);
    }

    addTeamEmployee(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/teams/' + dataObj.teamId + '/employees', dataObj);
    }

    removeTeamEmployee(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/teams/' + dataObj.teamId + '/employees', dataObj);
    }

     ////////////////////////////
    // Leaderboard Cloud Functions
    ////////////////////////////
    fetchLeaderboards()
    {
        return this.httpJson('GET','/organizations/' + localStorage.companyId + "/leaderboards");
    }

    fetchTeamChallengeLeaderboards() {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + "/leaderboards",{type:'challenge', recordCounts:true});
    }

    fetchLeaderboard(params)
    {
        params = params || {};
        return this.httpJson('GET','/organizations/' + localStorage.companyId + "/leaderboards/" + params.leaderboardId);
    }

    getLeaderboardMembersExport(params) {
        return this.http('GET', `/organizations/${localStorage.companyId}/leaderboards/${params.leaderboardId}/export`, params);
    }

    fetchLeaderboardTeam(params)
    {
        params = params || {};
        return this.httpJson('GET', "/teams/" + params.teamId + "/leaderboards/" + params.leaderboardId);
    }

    fetchRecords(obj)
    {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId +"/records", obj);
    }

    updateRecord(obj)
    {
        return this.httpJson('PATCH', '/organizations/' + localStorage.companyId +"/records/" + obj.userRecordId, obj);
    }

    //Invitations
    getInvitations(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/invitations', dataObj)
    }

    postInvitations(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/invitations', dataObj)
    }

    postInvitationRewards(dataObj) {
        dataObj.companyId = localStorage.companyId
        return this.httpJson('POST', '/organizations/invitation_rewards', dataObj)
    }

    // Rewards
    getRewards(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/rewards', dataObj)
        // + new URLSearchParams({...dataObj}));
    }

    addReward(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/rewards', dataObj);
    }

    updateReward(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/rewards/' + dataObj.rewardId, dataObj);
    }

    removeReward(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/rewards/' + dataObj.rewardId, dataObj);
    }

    addCompanyReward(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/rewards/' + dataObj.rewardId + '/subscribe', dataObj);
    }

    removeCompanyReward(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/rewards/' + dataObj.rewardId + '/unsubscribe', dataObj);
    }

    // Rewards V2
    getRewardsV2(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/rewards/v2/rewards`, dataObj);
    }

    getRewardCategories(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/rewards/v2/reward_categories`, dataObj);
    }

    addRewardV2(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/rewards/v2/rewards`, dataObj);
    }

    updateRewardV2(dataObj) {
        return  this.httpJson('PATCH', `/organizations/${localStorage.companyId}/rewards/v2/rewards/${dataObj.id}`, dataObj);
    }

    removeRewardV2(dataObj) {
        return this.httpJson('DELETE', `/organizations/${localStorage.companyId}/rewards/v2/rewards/${dataObj.rewardId}`, dataObj);
    }

    addRewardAction(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/rewards/v2/actions`, dataObj);
    }

    updateRewardAction(dataObj) {
        return this.httpJson('PATCH', `/organizations/${localStorage.companyId}/rewards/v2/actions/${dataObj.id}`, dataObj);
    }

    removeRewardAction(dataObj) {
        return this.httpJson('DELETE', `/organizations/${localStorage.companyId}/rewards/v2/actions/${dataObj.rewardActionId}`, dataObj);
    }

    removeRewardOption(dataObj) {
        return this.httpJson('DELETE', `/organizations/${localStorage.companyId}/rewards/v2/actions/${dataObj.rewardActionId}/options/${dataObj.rewardOptionId}`, dataObj);
    }

    awardBonusGems(dataObj){
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/bonus_gems`, dataObj);
    }

    uploadRewardCodes(dataObj){
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/rewards/v2/rewards/${dataObj.rewardId}/codes`, dataObj);
    }

    createCompanyAccount(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/budget', dataObj);
    }

    updateRewardsPlugin(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/budget', dataObj);
    }

    fundTangoCardAccount(dataObj){
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/workorders',dataObj);
    }

    removeFund(dataObj){
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/workorders/'+dataObj.id, dataObj);
    }
    approveFund(dataObj) {
        return this.httpJson('PATCH', `/organizations/${localStorage.companyId}/workorders/${dataObj.workorderId}`, dataObj)
    }

    addSurveySubscription(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId +'/surveys/'+dataObj.surveyId+'/subscription', {});
    }

    removeSurveySubscription(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId +'/surveys/'+dataObj.surveyId+'/subscription', {});
    }

    hraToggle(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId +'/surveys/'+dataObj.surveyId+'/'+dataObj.action, {});
    }

    hraNudge(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId +'/surveys/'+dataObj.surveyId+'/nudge', {});
    }

    getHRAActivity(dataObj){
        return this.httpJson('GET', '/organizations/' + localStorage.companyId +'/surveys/'+dataObj.type+'/data', {});
    }

    getRiskProfile(dataObj){
        dataObj = dataObj || {};
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/riskReport', dataObj);
    }
    // Segments
    viewSegment(dataObj){
        return this.httpJson('PATCH', '/organizations/' + localStorage.companyId + '/segments', dataObj);
    }

    buildSegment(dataObj){
        return this.httpJson('POST', '/organizations/' + localStorage.companyId +' /buildSegment', dataObj);
    }

    createSegment(dataObj){
        return this.httpJson('POST', '/organizations/' + localStorage.companyId +'/segments', dataObj);
    }

    getSegments(dataObj){
        return this.httpJson('GET', '/organizations/' + localStorage.companyId +'/segments', dataObj);
    }

    getSegmentOptions(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId +'/segments/segmentOptions', dataObj);
    }

    getSegmentOptionsForSurvey(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId +'/segments/survey/segmentOptions', dataObj);
    }

    rebuildSegment(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId +'/segments/'+dataObj.teamId+'/rebuild', dataObj);
    }

    getSegmentMembersExport(dataObj) {
        return this.http('GET', `/organizations/${localStorage.companyId}/segments/${dataObj.teamId}/members/export`, dataObj);
    }
    
    postSegmentIterableExport(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/segments/${dataObj.id}/iterable_export`, dataObj);
    }

    // Announcements
    getAnnouncements(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/announcements', dataObj);
    }

    addAnnouncement(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/announcements', dataObj);
    }

    getAnnouncement(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/announcements/' + dataObj.announcementId, dataObj);
    }

    updateAnnouncement(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId + '/announcements/' + dataObj.announcementId, dataObj);
    }

    removeAnnouncement(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/announcements/' + dataObj.announcementId, dataObj);
    }

    getAnnouncementRecipients(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/announcements/' + dataObj.announcementId + '/recipients', dataObj);
    }

    // Account
    getOrganization(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId, dataObj);
    }

    updateOrganization(dataObj) {
        return this.httpJson('PUT', '/organizations/' + localStorage.companyId, dataObj);
    }

    // Competitions

    getCompetitions(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/competitions', dataObj);
    }

    exportCompetition(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/competitions/' + dataObj.id + '/export', dataObj);
    }

    createCompetition(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/competitions', dataObj);
    }

    getSeasons(dataObj) {
        return this.httpJson('GET', '/organizations/' + localStorage.companyId + '/seasons', dataObj);
    }

    addSeason(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/seasons', dataObj);
    }

    modifySeason(dataObj) {
        return this.httpJson('PATCH', '/organizations/' + localStorage.companyId + '/seasons/' + dataObj.seasonId, dataObj);
    }

    removeSeason(dataObj) {
        return this.httpJson('DELETE', '/organizations/' + localStorage.companyId + '/seasons/' + dataObj.seasonId, dataObj);
    }

    getCompanyPluginAttribute(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/plugins/${dataObj.tag}/plugin_attribute/${dataObj.name}`);
    }

    updateCompanyPluginAttributeValue(dataObj) {
        return this.httpJson('PUT', `/organizations/${localStorage.companyId}/plugins/${dataObj.tag}/plugin_attribute/${dataObj.name}`, dataObj);
    }

    getEmployeeAttributes(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/employee_attributes`);
    }

    createEmployeeAttributes(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/employee_attributes`, dataObj);
    }


    setEmployeeAttribute(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/employees/${dataObj.employeeId}/values`, dataObj)
    }

    //Surveys
    getSurveys(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/surveys`, dataObj);
    }

    postSurvey(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/surveys`, dataObj);
    }
    
    removeSurvey(dataObj) {
        return this.httpJson('DELETE', `/organizations/${localStorage.companyId}/surveys/${dataObj.surveyId}`, dataObj);
    }

    getCampaigns(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/surveys_campaigns`, dataObj);
    }

    postCampaigns(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/surveys/${dataObj.surveyId}/surveys_campaigns`, dataObj);
    }
    
    removeCampaign(dataObj) {
        return this.httpJson('DELETE', `/organizations/${localStorage.companyId}/surveys_campaigns/${dataObj.campaignId}`, dataObj);
    }

    postUserSurveys(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/surveys/${dataObj.surveyId}/user_surveys`, dataObj);
    }

    postSponsors(dataObj) {
        return this.httpJson('POST', `/organizations/${localStorage.companyId}/sponsors`, dataObj);
    }

    searchSurveyQuestions(dataObj) {
        return this.httpJson('GET', `/organizations/${localStorage.companyId}/survey_questions`, dataObj);
    }

    postResetDemo(dataObj) {
        return this.httpJson('POST', '/organizations/' + localStorage.companyId + '/resetDemo', dataObj);
    }

    //Helpers    

    httpJson(args) {
        return this.http.apply(this, arguments).then(r => r.json());
    }

    buildRequest(method, url, dataObj, options) {
        options = options || {};
        var headers = {
            'X-Optimity-Platform': 'web',
            'X-Optimity-Appversion': packageDetails.version,
            'X-Optimity-Appbuild': packageDetails.buildno
        };

        var head = headers;

        if (localStorage.installationId) {
            head['X-Optimity-Installation-Id'] = localStorage.installationId;
        }

        if(localStorage.selectedLanguage) {
            head['Accept-Language'] = localStorage.selectedLanguage;
        }

        head['Content-Type'] = options.contentType || 'application/json';
        if(!options.absoluteUrl) {
            var apiUrl = options.apiUrl || Keys.API_URL;
            url = apiUrl + url;
        }
        url = encodeURI(url);
        var init = {
            credentials: "include",
            method: method,
            headers: head,
            // mode: 'no-cors'
        }
        if(method === 'GET') {
            if(dataObj!=null) {
                var params = Object.keys(dataObj);
                if(params.length > 0) {
                    url = url + '?' + queryString.stringify(dataObj);
                }
            }
        }
        else {
            init.body = options.body || JSON.stringify(dataObj);
        }

        //init.mode = 'no-cors'
        console.log('fetch - url, init: ');
        console.log(url);
        console.log(init);
        return {init, url}
    }

    http(method, url, dataObj, options) {
        var { init, url } = this.buildRequest(method, url, dataObj, options)
        return fetch(url, init).then(this.handleHttpErrors);
    }

    handleHttpErrors(response) {
        if (!response.ok) {
            console.error('handleHttpErrors', response);
            var statusText = response.statusText;
            var code = response.status;
            return new Promise((resolve,reject)=>{
                response.json().then((json)=>{
                    this.handleErrorCode(json.code);
                    reject(this.createHttpError(code, statusText, json));
                });
            });
        }
        return response;
    }

    handleErrorCode(code) {
        if(code == ErrorCodes.InvalidSession || code == ErrorCodes.NotAdmin) {
            console.log('handleErrorCode => InvalidSession');
            this.destroySession();
            return true;
        }
        return;
    }

    createHttpError(status, statusText, json) {
        var that = this;
        return ({
            ...json,
            status,
            statusText,
            //localizedError: that.i18n.translateErrorCode(json.code, status),
            toString:function(){
                return json.message//this.localizedError;
            }
        });
    }

}


export default new DatabaseService();
