import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Icon, Button, Header, Checkbox, Grid, Label } from 'semantic-ui-react';
import moment from 'moment';

import {getCompetitionImageUrl } from './tools.js';
import Utils from 'utils/Utils.js';
import LoadInline from '../loading/LoadInline.js';
import {colors, spacings, buttons, fontSizes} from 'style';
import LeaderboardContainer from 'modules/leaderboards/LeaderboardContainer.js';
import DeletePromptContainer from 'modules/misc/DeletePromptContainer.js';
var style = {
    content: {
        padding: '1px',
        position:'relative'
    },
    loaderContainer: {
        marginLeft:'50%'
    },
    subtitle: {
        textAlign: 'center',
    },
    card: {
        overflow:'hidden',
        color: '#fff',
        backgroundColor: colors.themeWhite,

        width:'32rem',
    },
    emptyCard: {
        overflow:'hidden',
        color: '#fff',
        backgroundColor: colors.themeWhite,
        height:'100%',
        minHeight: '25rem',
        width:'32rem',
    },
    header: {
        fontSize: '1em',
        color:colors.themeDarkerGrey,
    },
    description: {
        color:'#fff',
    },
    meta: {
        color:'#fff',
    },
    iconHeader: {
        padding:spacings.small,
        width:'100%',
        color: colors.themeBlue,
        fontSize: fontSizes.bodyL,
        //backgroundColor: colors.themeTeal,
    },
    imageBanner: {
        position: 'relative',
        height:'18rem',
        width:'32rem',
        backgroundSize:'cover',
        backgroundPositionY: 'center',
    },
    checkmark: {
        color : colors.themeTeal
    },
    toggle: {
        position: 'relative',
        top: '3px',
    },
    editButton: {
        //position: 'absolute',
        //top: '10px',
        //right: '10px',

        backgroundColor: colors.themeWhite,
        border: 'none',
        boxShadow: 'none',
        fontSize: '1.5rem',
        padding: '0px',
        margin: '0',
    },
    trophyIcon: {
        color: '#DAA520',
        margin: '0',
        opacity: '1'
    },
    editIcon: {
        margin: '0',
        opacity: '1',
        color: colors.themeDarkGrey,
    },
    date : {
    	marginLeft:'2rem',
    	color: colors.themeDarkerGrey,
    },
    containerCard : {
        boxShadow : 'none',
        background : 'none',
        width : '32rem'
    },
    button : {
        marginLeft: spacings.small,
        marginRight: spacings.small,
        ...buttons.long,

    },
    emptyBox : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
};


const ref = React.createRef();

const CompetitionCard = ({
    t,
    competition,
    onEdit,
    onSchedule,
    season,
    leaderboardRef,
    redirectLeaderboard,
    onExport
}) => {
var startsAt = moment(competition.startsAt);
var endsAt = moment(competition.endsAt);
var isMonth = startsAt.isValid() && endsAt.isValid() && startsAt.date() == 1 && endsAt.date() == 1 && moment(competition.startsAt).add(1, 'month').valueOf()  === endsAt.valueOf();

return <Card key={competition.id} style={style.containerCard} raised={false}>

    {competition.id && isMonth && <span style={style.date}>{startsAt.format("MMM YYYY")}</span>}
    {competition.id && !isMonth && <span style={style.date}>{startsAt.format("MMM DD, YYYY")} - {endsAt.isValid() && endsAt.format("MMM DD, YYYY") || t('Forever')}</span>}
    {!competition.id && <span style={style.date}>&nbsp;</span>}

    { !competition.id && <Card className='nohover' style={style.emptyCard}>
        <div style={style.emptyBox}>
            <Button style={style.button} onClick={(event)=>(onSchedule(event, competition))}>
                {t('Schedule New Competition')}
            </Button>
        </div>
    </Card>}

    { competition.id && <Card className='nohover' style={style.card}>
        <div style={style.iconHeader}>
            <Grid verticalAlign="middle" columns={16}>
                <Grid.Row>              
                    <Grid.Column textAlign="left" width={12}>                    
                    {competition.competition ? 
                        <Label color="teal" style={{marginRight: "1em"}}>{t('competition_pill')}</Label> : 
                        competition.isPrimary ? 
                            <Label color="blue" style={{marginRight: "1em"}}>{t('season_pill')}</Label> : 
                            <Label color="grey" style={{marginRight: "1em"}}>{t('non_primary_pill')}</Label>
                    }                    
                    {competition.leaderboard ? competition.leaderboard.title : competition.title }                    
                    </Grid.Column>                    
                    <Grid.Column textAlign="right" width={4}>
                    {/* {competition.competition && onExport && localStorage.master ==='true' &&
                        <Button
                         style={style.editButton}
                         className="categories-editButton"
                         onClick={() => onExport(competition.competition)}>
                            <Icon style={style.editIcon} name='download' />
                        </Button>
                    } */}
                    {leaderboardRef &&
                        <Button
                         style={style.editButton}
                         className="categories-editButton"
                         onClick={() => {redirectLeaderboard(competition)}}>
                        <Icon style={style.trophyIcon} name='trophy' />
                        </Button>
                    }
                        {onEdit && competition.competition && 
                            <Button
                                style={style.editButton}
                                className="categories-editButton"
                                onClick={(event)=>{onEdit(event, competition);}}
                            >
                                <Icon style={style.editIcon} name='setting'/>
                            </Button>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
        <div style={{...style.imageBanner, backgroundImage:Utils.url(getCompetitionImageUrl(competition))}}></div>
        {competition.leaderboard && <Card.Content style={style.header}>
            <Icon style={style.checkmark} name={competition.leaderboard.type == 'teams' ? 'users' : 'user'}/>
            {competition.leaderboard.type == 'teams' ? t('Team Competition') : t('Individual Competition')}
        </Card.Content>}
     </Card> }
</Card>
};


export default withTranslation('competitions')(CompetitionCard);
