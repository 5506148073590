import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Icon, Header, Checkbox, Popup } from 'semantic-ui-react';

import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import Utils from 'utils/Utils.js';
import defaultRewardImage from 'img/optimity-logo.png';
import moment from 'moment';

const style = {
    item: {
        width: '320px',
        textAlign: 'left',
        padding: spacings.small,
    },
    rewardsImage: {
        width: '100px',
        maxHeight: '100px',
    },
    rewardRow: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: fontSizes.bodyL,
        lineHeight: '1',
        marginTop: spacings.tiny,
        marginBottom: spacings.tiny,
    },
    checkboxContainer: {
        display: 'flex',
        verticalAlign: 'middle',
        // border: 'outset',
        // borderWidth: 1,
        paddingLeft: spacings.tiny,
        paddingRight: spacings.tiny,
        marginLeft: spacings.tiny,
        marginRight: spacings.tiny,
    },
    checkbox: {
        verticalAlign: 'middle',
        marginLeft: spacings.tiny,
        marginRight: spacings.tiny,
        display: 'inline-block',
    },
    rewardPrice: {
        display: 'flex',
        verticalAlign: 'middle',
        textAlign: 'left',
        display: 'inline-block',
        width: '80px',
    },
    rewardResources: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '80px',
        textAlign:'left'
    },
    star: {
        color:colors.themeBlue,
        position: 'relative',
        top: '2px',
    },
    editButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#fff',
        border: 'none',
        boxShadow: 'none',
        fontSize: '30px',
        width: '30px',
        height: '30px',
        padding: '0',
        margin: '0',
    },
    gemIcon: {
        color: colors.pointsBlue
    }

};

const RewardCardV2 = ({t, rewardAction, onToggleRewardOption, onEdit, rewardCategory, allowEdit}) => {
    let {
        id,
        title,
        subtitle,
        asset,
        rewardOptions=[],
        availableAt: startsAt,
        endsAt,
        team
    } = rewardAction

    startsAt = id ? moment(startsAt) : undefined;
    endsAt = id ? moment(endsAt) : undefined;

    return (
    <Card key={id} style={style.item}>
        <div>
            {asset && <img src={asset.url} alt={asset.label} style={style.rewardsImage} />}
            {Boolean(onEdit) && Boolean(allowEdit) &&
                <Button style={style.editButton} onClick={()=>onEdit(rewardAction, rewardCategory)}><Icon name='edit'/></Button>
            }
        </div>
        <p>{title}</p>
        <p style={{fontSize: 'small'}}>{subtitle}</p>
        {Boolean(localStorage.master  === 'true') && id && <span style={{fontSize: 'small'}}>Reward Action Id: {id}</span>}
        {startsAt && <span style={{fontSize: 'small'}}>Starts : {startsAt.isValid() ? startsAt.format("MMM DD, YYYY") : 'Unknown'}</span>}
        {endsAt && <span style={{fontSize: 'small'}}>Ends : {endsAt.isValid() ? endsAt.format("MMM DD, YYYY") : 'Never'}</span>}
        {team && <span style={{fontSize: 'small'}}>Segment : {team.name || 'unknown'}</span>}
        {rewardOptions.map(rewardOption => {
            const { reward } = rewardOption
            return (
                <div key={rewardOption.id} style={style.rewardRow}>
                    <div style={style.rewardPrice}><Icon name="dollar"/>{reward.price / 100} {reward.currency?.code}</div>

                        <div style={style.checkboxContainer}>
                            <i  class={`${rewardOption.visible ? 'eye' : 'eye slash'}` + " circle icon"} ></i>
                            <Popup trigger={(
                                
                                <Checkbox style={style.checkbox}
                                    disabled={!Boolean(allowEdit)}
                                    className='green' 
                                    key={rewardOption.id} 
                                    toggle
                                    checked={rewardOption.visible}
                                    onChange={()=>onToggleRewardOption(rewardAction, rewardOption)}
                                />
                                
                            )}>
                                <span style={{whiteSpace: 'pre-wrap'}}>{rewardOption.visible ? 'Mark Invisible' : 'Mark Visible'}</span>
                            </Popup>
                        </div>
                    

                    <div style={style.rewardResources}><Icon name="gem" style={style.gemIcon}/> {rewardOption.resources}</div>
                </div>
            )
        })}
    </Card> 
)};

export default withTranslation('rewards')(RewardCardV2);
