export const colors = {
    themeTeal: '#28b8be',
    themeLightBlue: '#59c9ff',
    themeBlue: '#007fb9',
    themeGrey: 'rgb(249,249,249)',
    themeDarkGrey1_2: '#dddedf',
    themeDarkGrey: '#bbbdbf',
    themeDarkerGrey: '#6e6e6e',
    pointsYellow:'#f5a623',
    pointsBlue:'#007fb9',
    themeOrange: '#dd5f07',
    themeMaroon: '#aa3333',
    errorRed: '#db2828',
    warningRed: '#da5454',
    teamPurple: '#926dde',
    themeWhite : '#fff',
    themeBlack: "#000",
    themePrimaryBlue: "#355DEE"
}

export const fontSizes = {
    h1:    '48px',  
    h2:    '40px',  
    h3:    '32px',  
    h4:    '24px',  
    bodyL: '18px',  
    body:  '16px',  
    bodyS: '14px',  
}

export const fontFamilies = {
    base:   "'Source Sans Pro', sans-serif"
}

export const fonts = {
    h1   : { fontSize:fontSizes.h1   , fontFamily: fontFamilies.base},  
    h2   : { fontSize:fontSizes.h2   , fontFamily: fontFamilies.base},  
    h3   : { fontSize:fontSizes.h3   , fontFamily: fontFamilies.base},  
    h4   : { fontSize:fontSizes.h4   , fontFamily: fontFamilies.base, fontStyle: 'oblique'},  
    bodyL: { fontSize:fontSizes.bodyL, fontFamily: fontFamilies.base },  
    body : { fontSize:fontSizes.body , fontFamily: fontFamilies.base },  
    bodyS: { fontSize:fontSizes.bodyS, fontFamily: fontFamilies.base },  
}

export const shadow = 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px';

export const spacings = {
    micro:  '4px',
    tiny:   '8px',
    small: '16px',
    base:  '24px',
    large: '48px',
    xlarge:'64px',
}

export const layout = {
    screenWidth: '992px',
    screenShadow: 'rgba(0, 0, 0, 0.10) 0px 0px 16px 0px'
}


export const buttons = {};
buttons.base = {
    fontWeight: 'normal',
    ...fonts.body,
    lineHeight: '1.5',
    borderRadius: '100px',
    backgroundColor: colors.themeTeal,
    color: '#fff',
    width: '240px',
    boxShadow: shadow,
};

buttons.secondary = {
    fontWeight: 'normal',
    ...fonts.body,
    lineHeight: '1.5',
    borderRadius: '100px',
    backgroundColor: colors.themeWhite,
    color: colors.themeTeal,
    width: '240px',
    boxShadow: shadow,
};

buttons.long = {
    ...buttons.base,
    width: '320px'
};


buttons.short = {
    ...buttons.base,
    width: '160px'
};

buttons.longWidth = {
    width: '320px'
};

buttons.mediumWidth = {
    width: '240px'
};

buttons.shortWidth = {
    width: '160px'
};
buttons.delete = {
    ...buttons.secondary,
    ...buttons.shortWidth,
    color: colors.errorRed,
    backgroundColor : colors.themeWhite,
};

