import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';
import {getCategories, getCategoriesByIds} from 'reducers/categories.js';
import {fetchTeams} from 'actions/teams.js'
import {fetchCompetitions} from 'actions/competitions.js'
import {fetchCategories, toggleCategory} from 'actions/categories.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import jstz from 'jstz';
import moment from 'moment-timezone';
import { getUserRoles } from 'reducers/user.js';

import EditCompetitionModal from './EditCompetitionModal.js';

const Languages = [
    {key:'en',  text:'English (US)',  value:'en'},
    {key:'en_ca',  text:'English (CA)',  value:'en_ca'},
    {key:'fr',  text:'Français', value:'fr'},
];
class EditCompetitionModalContainer extends Component {
    constructor(props) {
        super(props);
        var season = this.props.season || {};
        let now = moment()/*.add(1,'hour').startOf('hour')*/.toDate();
        this.state = {
        	busy : true,
            teamsSegments: [],
            addedTeamsSegments: [],
            competitionOptions : [],
            season : season || {},
            categoryToDisplay:null,
            categoryIdList: [],
            now,
            uploading: false
        };

        if(season.startsAt)
            season.startsAt = new Date(season.startsAt)
        // if(!season.id && (!season.startsAt || season.startsAt.getTime() < Date.now()))
        //     season.startsAt = now//new Date()
        if(season.endsAt)
            season.endsAt = new Date(season.endsAt)
            
        if(season.startsAt && season.startsAt.getTime() < Date.now() && (!season.endsAt || season.endsAt && season.endsAt.getTime() > Date.now())) {
            season.active = true;
        }

        if(season.preEnrollmentAt) {
            season.preEnrollmentAt = new Date(season.preEnrollmentAt)
        }

        // if(!season.id && !season.preEnrollmentAt) {
        //     season.preEnrollmentAt = moment(season.startsAt).subtract(2, 'weeks') < moment() ? season.startsAt : moment(season.startsAt).subtract(2, 'weeks').toDate()
        // } 

        this.state.season.hasFeed = !!this.state.season.publicFeedId
        this.state.season.hasLeaderboard = !!this.state.season.leaderboard?.hidden === false
        this.state.season.hasMilestones = !!this.state.season.seasonMilestones
        this.state.milestones = season.seasonMilestones;
        if(season.leaderboard)
            this.state.rewards = season.leaderboard.leaderboardRewards;
        this.deleteModalRef = React.createRef();
        if(season.seasonCategories)
            this.state.categoryIdList = _.pluck(season.seasonCategories, "categoryId");
        if(season.seasonCompanyAnnouncements)
            this.state.announcements = season.seasonCompanyAnnouncements;
        if(!this.state.season.timeZone)
            this.state.season.timeZone = (jstz.determine()).name()

        if(!this.state.season.locales) {
            this.state.season.locales = {
                'en_ca': {
                    enabled : true,
                    locale : 'en_ca',
                    title : moment(season.startsAt).tz(season.timeZone).locale('en-CA').format("MMMM") + " Challenge" 
                },
                'en': {
                    enabled : true,
                    locale : 'en',
                    title : moment(season.startsAt).tz(season.timeZone).locale('en').format("MMMM") + " Challenge" 
                },
                'fr':{
                    enabled : true,
                    locale : 'fr',
                    title : "Défi de " + moment(season.startsAt).tz(season.timeZone).locale('fr').format("MMMM")
                }
            }
        }

        this.doSearchDebounced = _.debounce(this.doSearch, 300)
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onAddSegmentTeam = this.onAddSegmentTeam.bind(this);
    }

    componentDidMount() {
        this.filterCategories()
    }

    componentWillMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.categoriesProp != prevProps.categoriesProp || this.state.competition != prevState.competition) {
            this.filterCategories()
        }
    }

    onRemoveSegmentTeam = (segment) => {
        let addedTeamsSegments = this.state.addedTeamsSegments.filter(e => e.id !== segment.id)
        this.setState({ addedTeamsSegments });
    }

    doSearch() {
        if(this.state.search) {
            this.setState({loadingTeamSegments:true});
            DatabaseService.getTeams({
                    'includeSegments' : 1,
                    'like.name' : this.state.search
                })
                .then(result => {
                    this.setState({teamsSegments:result.teams});
                })
                .catch(error => {
                    window.alert('An error occurred - check logs');
                    console.error(error);
                })
                .then(result => {
                    this.setState({loadingTeamSegments:false});
                });
        } else {
            this.setState({teamsSegments:[]})
        }
    }

    onAddSegmentTeam(segment) {

        this.setState(state=>{
            if(_.findWhere(state.addedTeamsSegments,{id:segment.id})===undefined) {
                return {addedTeamsSegments:[segment].concat(state.addedTeamsSegments),
                        teamsSegments:[],
                        search:''}
            } else {
                return {};
            }
        });

    }

    onChangeSearch(event) {
        var search = event.target.value;
        this.setState({search: search}, this.doSearchDebounced);
    }

    filterCategories() {
        if(this.props.categoriesProp) {
            var filteredList = this.props.categoriesProp.filter(cat => this.state.categoryIdList.includes(cat.id));
            this.setState({categories: filteredList});
        }
    }

    loadData() {
        var _finally = (result) => {

            console.log('finally... -> ')
            console.log(result)

            this.setState({
                busy: false
            });
        }

        var p1 = this.props.fetchCategories({})

        var p2 = DatabaseService.getCompetitions().then((result) => {
            var competitions = result.competitions || [];

            console.log('competitions --> ', competitions)

            for(var i in competitions) {
                if(competitions[i].competitionRewards) {
                    competitions[i].competitionRewards.sort(function(a, b) { return b.rank ? a.rank - b.rank : -1; })
                }
                // if(competitions[i].locales) {
                //     competitions[i].locales = competitions[i].locales.reduce((a,v) => { a[v.locale] = v; return a}, {})
                // }
            }
            var state = {
                competitions : competitions,
                competitionOptions: competitions.map(function(a, key) { return { key : key, value : a.id, text : a.title } })
            }

            if(this.state.season.competitionId) {
                state.competition = this.getCompetition(this.state.season.competitionId, competitions)
            }

            this.setState(state);
        })

        var p3 = DatabaseService.getTeams({}).then(
            (result) => { 
                var state = {
                    teams : result.teams
                }

                this.setState(state);
                return result.teams

            }, (error) => {   console.error(error) });

        var p4 = DatabaseService.getSegments().then(
            (result) => { 
                var state = {
                    segments : result.rows
                }

                this.setState(state);
                return result.rows

            }, (error) => { console.error(error) });

        //var p3 = this.props.fetchTeams({})

        return Promise.all([p1, p2, p3, p4]).catch((error)=> {
            window.alert('An error occurred - check logs');
            console.error(error);
        }).then(_finally,_finally, _finally, _finally);


    }

    getCompetition(competitionId, competitions) {
        competitions = competitions || this.state.competitions;
        var competition = competitions.find(function(a) {return a.id === competitionId});
        if(competition) {
            if(competition.id === this.state.season.competitionId) {
                // fill season customization
            }
            return competition;
        }
    }

    onLanguageInputChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState(Utils.set({...this.state}, name, value))
    }

    onUploadPhoto = () => {
        this.setState({choosePhotoIsOpen:!this.state.choosePhotoIsOpen});
    }

    onConfirmPhoto = (photo) => {
        this.setState(state => {
            let s = {...state};
            Utils.set(s, 'season.leaderboard.banner.dataURL', photo);
            return s;
        });
    }

    // Events

    onSubmit=(event) => {

        console.log(event)

        event && event.stopPropagation()

        var season = this.state.season;
        var endsAt = season.endsAt;//new Date(season.startsAt)
        //endsAt.setMonth(endsAt.getMonth()+1)

        // don't use busy : true because it hides all the UI
        this.setState({ uploading: true });
        var data = {
            competitionId : this.state.competition.id,
            seasonId : season.id,
            season : {
                feed: season.feed,
                locales : season.locales,
                leaderboard: season.leaderboard,
                endsAt :  moment.tz(moment(this.state.season.endsAt).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this.state.season.timeZone).toDate(),
                timeZone : this.state.season.timeZone,
                hasFeed: this.state.season.hasFeed,
                hasLeaderboard: this.state.season.hasLeaderboard,
                isSegmented: this.state.season.isSegmented
            }
        };

        if(!season.id || (season.id && !season.active)) {
            data.season.startsAt = moment.tz(moment(this.state.season.startsAt).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this.state.season.timeZone).toDate();
            data.season.preEnrollmentAt = moment.tz(moment(this.state.season.preEnrollmentAt).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this.state.season.timeZone).toDate();
        }



        console.log(season)

        if(!season.id) {
            if(this.state.season.leaderboard.type !== 'teams') {
                data.season.seasonMilestones = this.state.competition.competitionMilestones.filter(a=>a.enabled);
                
                if(this.state.season.hasLeaderboard)
                    data.season.seasonRewards = this.state.competition.competitionRewards.filter(a=>a.enabled);
            }

            console.log(this.state.competition.type)

            if(this.state.competition.type === 'teams' || this.state.competition.type === 'company') {
                data.season.teamIds = this.state.addedTeamsSegments.map(segment => segment.id);
            }
            
            //teamsCompetition

            data.season.seasonCompanyAnnouncements = this.state.announcements.map(function(a) {
                return {
                    ...a,
                    sendAt : moment.tz(moment(a.sendAt).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', data.season.timeZone).toDate()
                }
            });
            data.season.type = this.state.season.leaderboard.type;
        }

        console.log(data);



        return DatabaseService[season.id ? 'modifySeason' : 'addSeason'](data).then(() => {
            return this.props.fetchCompetitions({}, true)
        }).then(() => {
            this.props.onClose();
        }).catch(() => {
            this.setState({ busy : false, uploading: false });
        });
    }

    onEndCompetition=(event) => {
        this.state.season.endsAt = new Date();
        this.onSubmit(event);
    }

    onDelete=(deleteYes) => {
        if(deleteYes){
            //event && event.stopPropagation()
            // don't use busy : true because it hides all the UI
            this.setState({ uploading: true });
            return DatabaseService.removeSeason({seasonId : this.state.season.id}).then(() => {
                return this.props.fetchCompetitions({}, true)
            }, (e) => {
                this.setState({ busy : false, uploading: true });
                return e;
            })
            .then(() => {
                this.props.onClose();
            });
        }
    }

    onToggleCategory = (category) => {

        this.props.toggleCategory(category.id)
            .catch((error)=> {
                if(error.code == 1100){
                    window.alert("You cannot add any more activity packs because your organization is already at the limit. You must unsubscribe from other activity packs before you can add this one.");
                }else {
                    window.alert('An error occurred - check logs');
                }
                console.error(error);
            });
    }

    onClickCompetition = (event, value) => {
        if(!this.state.competition || this.state.competition.id !== value) {
            var competition = this.getCompetition(value);
            if(!this.state.season.leaderboard) {
                this.state.season.leaderboard = {
                    type: competition.type,
                    locales: competition.locales
                }
            }
            if(this.state.season.locales)
                Object.values(this.state.season.locales).forEach(l=>{
                    l.description = competition.locales[l.locale]?.description
                })
            if(this.state.season?.leaderboard?.locales)
                Object.values(this.state.season.leaderboard.locales).forEach(l=>{
                    l.title = competition.locales[l.locale]?.title
                })
            if(!this.state.season.feed) {
                this.state.season.feed = {}
            }
            this.state.season.hasLeaderboard = competition.hasLeaderboard
            this.state.season.feed.allowAssets = competition.allowAssets
            this.state.season.hasFeed = competition.hasFeed
            this.state.season.hasMilestones = !!competition.competitionMilestones

            this.setState({
                season: {...this.state.season},
                competition : competition,
                rewards : competition.competitionRewards,
                milestones : competition.competitionMilestones,
                competitionCategories : competition.competitionCategories,
                announcements : [
                    {
                        type: 1,
                        sendAt : moment(this.state.season.startsAt).add(-7, 'days').startOf('day').hours('12').toDate(),
                        enabled : false
                        //enabled : moment(this.state.season.startsAt).add(-7, 'days').startOf('day').hours('12').toDate().getTime() > Date.now(),
                    },
                    {
                        type: 2,
                        sendAt : moment(this.state.season.startsAt).startOf('day').hours('10').toDate(),
                        enabled : false
                    },
                    {
                        type: 3,
                        sendAt : moment(this.state.season.startsAt).add(7, 'days').startOf('day').hours('10').toDate(),
                        enabled : false
                        //enabled : moment(this.state.season.startsAt).add(7, 'days').startOf('day').hours('10').toDate().getTime() < moment(this.state.season.endsAt).startOf('day').toDate().getTime()
                    },
                    {
                        type: 4,
                        sendAt : moment(this.state.season.endsAt).startOf('day').hours('10').toDate(),
                        enabled : false
                    }
                ],
                categoryIdList:_.pluck(competition.competitionCategories, "categoryId")
            })
            console.log(this.state)
        }
    }

    onClickChange = (event, value) => {
        event && event.stopPropagation()
        this.setState({
            competition : null
        });
    }

    onChangeRankPoints = (e, p, index) => {
        this.setState(Utils.set({...this.state}, index, p.value))
    }

    onChangeCheckbox = (e, p, index) => {
        this.setState(Utils.set({...this.state}, index, p.checked))
    }

    onChangeTeamCheckbox = (e, p, index) => {
        this.setState(Utils.set({...this.state}, index, p.checked))
    }

    onDateChange = (value, index) => {

        console.log(index)
        console.log(value)

        if (!value) 
            return false

        this.setState(Utils.set({...this.state}, index, value))
        if(index == 'season.startsAt' || index == 'season.endsAt') {
            this.setState(Utils.set({...this.state}, 'announcements', [
                {
                    type: 1,
                    sendAt : moment(this.state.season.startsAt).add(-7, 'days').startOf('day').hours('10').toDate(),
                    enabled: false,
                    //enabled : moment(this.state.season.startsAt).add(-7, 'days').startOf('day').hours('10').toDate().getTime() > Date.now()
                },
                {
                    type: 2,
                    sendAt : moment(this.state.season.startsAt).startOf('day').hours('10').toDate(),
                    enabled: false,
                    //enabled : true
                },
                {
                    type: 3,
                    sendAt : moment(this.state.season.startsAt).add(7, 'days').startOf('day').hours('10').toDate(),
                    enabled: false,
                    //enabled : moment(this.state.season.startsAt).add(7, 'days').startOf('day').hours('10').toDate().getTime() < moment(this.state.season.endsAt).startOf('day').toDate().getTime()
                },
                {
                    type: 4,
                    sendAt : moment(this.state.season.endsAt).startOf('day').hours('8').toDate(),
                    enabled: false,
                    //enabled : true
                }
            ]))
        }
    }

    onTimeZoneChange = (value, index) => {
        //var oldTimeZone = this.state.season.timeZone;
        this.setState(Utils.set({...this.state}, index, value))
        // console.log(moment(this.state.season.startsAt).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this.state.season.timeZone)
        // var startsAt = moment.tz(moment(this.state.season.startsAt).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this.state.season.timeZone);
        // var endsAt = moment.tz(moment(this.state.season.endsAt).format('YYYY-MM-DDTHH:mm:ss'), 'YYYY-MM-DDTHH:mm:ss', this.state.season.timeZone);
        // console.log( startsAt.format())
        // this.setState(Utils.set({...this.state}, "season.startsAt", startsAt.toDate()))
        // this.setState(Utils.set({...this.state}, "season.endsAt", endsAt.toDate()))
        // this.setState(Utils.set({...this.state}, 'announcements', [
        //     {
        //         type: 1,
        //         sendAt : moment(this.state.season.startsAt).tz(this.state.season.timeZone).add(-7, 'days').startOf('day').hours('10').toDate(),
        //         enabled : moment(this.state.season.startsAt).tz(this.state.season.timeZone).add(-7, 'days').startOf('day').hours('10').toDate().getTime() > Date.now()
        //     },
        //     {
        //         type: 2,
        //         sendAt : moment(this.state.season.startsAt).tz(this.state.season.timeZone).startOf('day').hours('10').toDate(),
        //         enabled : true
        //     },
        //     {
        //         type: 3,
        //         sendAt : moment(this.state.season.startsAt).tz(this.state.season.timeZone).add(7, 'days').startOf('day').hours('10').toDate(),
        //         enabled : moment(this.state.season.startsAt).tz(this.state.season.timeZone).add(7, 'days').startOf('day').hours('10').toDate().getTime() < moment(this.state.season.endsAt).startOf('day').toDate().getTime()
        //     },
        //     {
        //         type: 4,
        //         sendAt : moment(this.state.season.endsAt).tz(this.state.season.timeZone).startOf('day').hours('8').toDate(),
        //         enabled : true
        //     }
        // ]))
    }

    // Render

    render() {
        return (
            <EditCompetitionModal
                {...this.props}
                {...this.state}
                onLanguageInputChange={this.onLanguageInputChange}
                onDateChange={this.onDateChange}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onClickCompetition={this.onClickCompetition}
                onClickChange={this.onClickChange}
                onEndCompetition={this.onEndCompetition}
                deleteModalRef = {this.deleteModalRef}
                onToggleCategory={this.onToggleCategory}
                onChangeRankPoints={this.onChangeRankPoints}
                onChangeCheckbox={this.onChangeCheckbox}
                onChangeTeamCheckbox={this.onChangeTeamCheckbox}
                onChangeSearch={this.onChangeSearch}
                onTimeZoneChange={this.onTimeZoneChange}
                onAddSegmentTeam={this.onAddSegmentTeam}
                onRemoveSegmentTeam={this.onRemoveSegmentTeam}
                languages={Languages}
                onUploadPhoto={this.onUploadPhoto}
                onConfirmPhoto={this.onConfirmPhoto}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        categoriesProp:getCategories(state.categories),
        roles: getUserRoles(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategories: (params, force) => dispatch(fetchCategories(params, force)),
        fetchTeams: (params, force) => dispatch(fetchTeams([params, force])),
        toggleCategory: id => dispatch(toggleCategory(id)),
        fetchCompetitions: (params, force) => dispatch(fetchCompetitions(params, force)),
        dispatch: dispatch
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation(['competitions', 'categories'])(EditCompetitionModalContainer)));
