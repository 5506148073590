import {Popup } from 'semantic-ui-react';
const styles = {
    flex: {
        display: "flex", 
        alignItems: "start", 
        gap: ".5rem",
    }
}
const WithHint = ({children, hint=''}) => {

    if (!hint)
        return children

    return (
        <div style={{...(styles.flex)}} >
            {children}
            <Popup trigger={(<i class="info circle icon" ></i>)}>
                <p style={{whiteSpace: 'pre-wrap'}}>{hint}</p>
            </Popup>
        </div>
        
    )
}

export default WithHint